<template>
  <div id="app">
    <!-- <router-view /> -->
    <keep-alive>
      <!-- 里面是当需要缓存时   显示组件的 router-view-->
      <router-view v-if="$route.meta.isAlive"></router-view>
    </keep-alive>
    <!-- 外面是不需要缓存时 -->
    <router-view v-if="!$route.meta.isAlive"></router-view>
  </div>
</template>

<script>
import { param2Obj, setThemes } from '@/utils';
import { setToken } from '@/utils/auth';

function getRem(designWidth, proportion) {
  const html = document.getElementsByTagName('html')[0];
  const screenWidth = document.body.clientWidth || document.documentElement.clientWidth;
  html.style.fontSize = (screenWidth / designWidth) * proportion + 'px';
}

export default {
  name: 'app',
  components: {},
  beforeCreate() {
    const query = param2Obj(location.href);
    sessionStorage.setItem('_appBarHeight', query.appBarHeight ?? 0);
    const root = document.documentElement;
    root.style.setProperty('--appBarHeight', (query.appBarHeight || 0) + 'px');
    setToken(query.token, query.appSecret, query.userId, query.appBarHieght);
    query && query.userId && sessionStorage.setItem('_userId', query.userId);
    setThemes(query.mode);
    getRem(750, 100);
  },
  created: function () {
    window.setThemes = (mode) => {
      setThemes(mode);
    };

    //适配rem
    window.onload = function () {
      /*750代表设计师给的设计稿的宽度，你的设计稿是多少，就写多少;100代表换算比例，这里写100是
		为了以后好算,比如，你测量的一个宽度是100px,就可以写为1rem,以及1px=0.01rem等等*/
      getRem(750, 100);
    };

    window.onresize = function () {
      getRem(750, 100);
    };
  },
};
</script>

<style lang="scss">
* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;
  list-style: none;
  box-sizing: border-box;
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
}
html,
body {
  width: 100%;
  min-height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  position: fixed;
  overflow: auto;

  -webkit-touch-callout: none;
  -moz-touch-callout: none;
  -ms-touch-callout: none;
  touch-callout: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  user-select: none;

  @include background_color('bg');
}
#app {
  flex: 1;
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 0;
  top: 0;
  overflow: scroll;
  width: 100%;
  height: 100%;
  /*@include background_color("index_bg");*/
  // -webkit-overflow-scrolling: touch;
}

.amap-marker-label {
  // width: 2.46rem;
  // height: 0.96rem;
  background: #ffffff;
  box-shadow: 0rem 0rem 0.12rem 0rem rgba(0, 0, 0, 0.1);
  border-radius: 0.3rem;
  text-align: center;
  font-size: 0.25rem;
  color: #222222;
  font-weight: 500;
  border: none;
  padding: 0.12rem 0.24rem;
  box-sizing: border-box;
  line-height: 0.36rem;
}

.m-message-content {
  height: 25px;
  line-height: 28px;
}
</style>
