<template>
  <div v-show="loadingCircle">
    <div class="mask"></div>
    <img
      class="circleBlueLoading"
      :style="{ top: `${bodyHeight / 2 - 20}px` }"
      src="../../assets/imgs/circleBlueLoading.png"
    />
  </div>
</template>

<script>
export default {
  name: 'loadingCircle',
  data() {
    return {
      bodyHeight: window.screen.height,
    };
  },
  props: {
    loadingCircle: {
      type: Boolean,
      default: false,
    },
    LoadingText: {
      type: String,
      default: '服务正在加载...',
      //  default: () => []
    },
  },
  created() {
    // var that = this;
    // this.bus.$on("loading", function (data) {
    //   that.loading = !!data;
    // });
  },
};
</script>

<style scoped lang="scss">
.mask {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: local;
}
.circleBlueLoading {
  width: 40px;
  height: 40px;
  position: fixed;
  animation: rotate 0.7s linear infinite;
  top: 50%;
  left: 50%;
  margin-left: -20px;
  z-index: 999;
}
@keyframes rotate {
  to {
    transform: rotate(360deg);
  }
}
</style>
