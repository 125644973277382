/*
 * @Author: zhaohuaiyuan zhaohuaiyuan@neolix.cn
 * @Date: 2022-07-28 22:44:57
 * @LastEditors: zhaohuaiyuan zhaohuaiyuan@neolix.cn
 * @LastEditTime: 2022-07-30 20:49:13
 * @FilePath: /neolixapph5/src/utils/request.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Vue from 'vue';
import axios from 'axios';
import qs from 'qs';
import { getToken, encrypt } from './auth';
import { neterrorPage } from './index';
import env from '../../env';
import Message from 'vue-m-message';

// const baseURL = "https://aqua.cybertron.neolix.net/";
// let baseURL = "https://aqua.test.neolix.net/";
// let baseURL = "https://aqua.uat.neolix.net/";
//生产环境
// const baseURL = "https://aqua.cybertron.neolix.net/";
// const baseURL2 = 'https://scapi.neolix.net/';
const baseURL = `${env.baseURL}`;
const baseURL2 = `${env.baseURL2}`;
const API_AD_SERVER = `${env.API_AD_SERVER}`;
const request = axios.create({
  baseURL,
  timeout: 5000,
});
request.interceptors.request.use(
  (config) => {
    config.timeout = 30000;
    if (
      config.url.includes('/app-center') ||
      config.url.includes('/aqua-center') ||
      config.url.includes('/vehicle-center') ||
      config.url.includes('/order-center') ||
      config.url.includes('/dashboard-center') ||
      config.url.includes('/user-center') ||
      config.url.includes('/nem-center')
    ) {
      config.baseURL = baseURL2;
    } else if (config.url.includes('/ndp/')) {
      config.baseURL = API_AD_SERVER;
    }
    // Nem接口临时使用
    // if (config.url.includes('/nem-center')) {
    //   config.baseURL = 'https://scapi.test.neolix.net';
    // }
    config.data?.loading && Vue.$loading.start('加载中');

    if (
      config.method === 'post' &&
      config.headers['Content-Type'] == 'application/x-www-form-urlencoded'
    ) {
      config.data = qs.stringify(config.data);
    } else {
      config.headers['Content-Type'] == 'application/json';
    }
    config.headers['Authorization'] = getToken();
    const { timestamp, nonce, signature } = encrypt();
    config.headers['Timestamp'] = timestamp;
    config.headers['Nonce'] = nonce;
    config.headers['Signature'] = signature;
    return config;
  },
  (error) => {
    Vue.$loading.end();
    console.log(error);
    return Promise.reject(error);
  }
);

request.interceptors.response.use(
  (response) => {
    Vue.$loading.end();
    Vue.$loadingOpendoor.end();
    Vue.$loadingCircle.end();
    const res = response.data.result || response.data.data;
    const code = response.data?.meta?.code || response.data.code;
    const msg = response.data?.meta?.msg || response.data.msg;
    if (response.config.customHandle) {
      return response.data;
    }
    switch (code) {
      case '10000':
        return res;
      // return {code:code, msg:msg};
      case '20185':
        return { code: code, msg: msg };
      case '10033':
        window.location.href = 'neolixjs://webview?url=login';
        return Promise.reject(new Error(msg || 'Need Login'));
      case '20190':
        return Promise.reject(new Error(msg));
      case '10021':
        return Promise.reject({ code: code, msg: msg });
      case '10178':
        return Promise.reject({ code: code, msg: msg });
      // case '500':
      //   return Promise.reject({ code: code, msg: msg });

      case '40005':
      case '40006':
      case '40007':
        return Promise.reject({ code, msg });
      default:
        Message({
          title: msg,
          type: 'error',
          collapsable: false,
        });
        return Promise.reject(new Error(msg || 'Error'));
    }
  },
  (error) => {
    Vue.$loading.end();
    neterrorPage();
    return Promise.reject(error);
  }
);

export default request;
