export default {
  state: {
    lockGestureMap: false,
    isShowLabelLogisticsMap: false, // 是否显示POI的名字
    isShowHighPrecisionLogisticsMap: false, // 是否显示高精图层
    drawStatus: 0, //   0-没绘制状态 1-普通poi采集 2-路线 3-围栏
    isShowdetail: 0, //  0-没展示详情 1-poi详情 2-路线详情  3-网点详情  4-高精POI采集 5-校准POI位置
    authBtn: [], // 地图标绘的按钮权限
    parkInfo: {}, // 当前选择的网格
  },
  getters: {
    getisShowLabelLogisticsMap: (state) => state.isShowLabelLogisticsMap,
    getisShowHighPrecisionLogisticsMap: (state) => state.isShowHighPrecisionLogisticsMap,
    getDrawStatus: (state) => state.drawStatus,
    getIsShowdetail: (state) => state.isShowdetail,
    getAuthBtn: (state) => state.authBtn,
    getLockGestureMap: (state) => state.lockGestureMap,
    getParkInfo: (state) => state.parkInfo,
  },
  mutations: {
    updateLockGestureMap(state, payload) {
      state.lockGestureMap = payload;
    },
    updateisShowLabelLogisticsMap(state, payload) {
      state.isShowLabelLogisticsMap = payload;
    },
    updateisShowHighPrecisionLogisticsMap(state, payload) {
      state.isShowHighPrecisionLogisticsMap = payload;
    },
    updateDrawStatus(state, payload) {
      state.drawStatus = payload;
    },
    updateIsShowdetail(state, payload) {
      state.isShowdetail = payload;
    },
    updateAuthBtn(state, payload) {
      state.authBtn = payload;
    },
    updateParkInfo(state, payload) {
      state.parkInfo = payload;
    },
  },
  actions: {},
};
