import Vuex from 'vuex';
import Vue from 'vue';

import StationManagement from './modules/StationManagement';
import LogisticsMap from './modules/LogisticsMap';

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    StationManagement,
    LogisticsMap,
  },
  plugins: [],
});
export default store;
