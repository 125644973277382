import store from '../../store/index';
export default {
  inserted(el, binding, vnode) {
    const { value } = binding;
    const authBtn = store.getters.getAuthBtn;
    if (value && value instanceof Array && value.length > 0) {
      const permissionRoles = value;

      const hasPermission = authBtn.some((role) => {
        return permissionRoles.includes(role);
      });

      if (!hasPermission) {
        el.parentNode && el.parentNode.removeChild(el);
      }
    } else {
      throw new Error(`need roles! Like v-permission="['admin','editor']"`);
    }
  },
};
